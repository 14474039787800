import React, { useEffect } from 'react';
import { DatePicker, Select, Skeleton } from 'antd';
import moment from 'moment';
import { InteractionOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { MainRoutes } from '../../config/routes';
// import StatsCard from '../../components/StatsCard/StatsCard';
import LineChart from '../../components/LineChart/LineChart';
import { useActions, useState } from '../../overmind';
import './Dashboard.css';

const { Option } = Select;

const doughnutLabels = [
  'Activos',
  'Cancelados',
  'Expirados',
];

const Dashboard = () => {
  const actions = useActions();
  const state = useState();
  const history: any = useHistory();
  const location = useLocation();
  const {
    dashboard: {
      processSummary, integrationList, selectedIntegration,
      selectedYear, availableYears,
      chartData,
    },
  } = state;
  useEffect(() => {
    // set breadCrumb
    actions.app.navigateMain({
      ...MainRoutes.dash,
      history: location.key,
    });
    actions.dashboard.initialize();
    actions.dashboard.getIntegrations();
  }, []);

  useEffect(() => {
    actions.dashboard.initialize();
  }, [selectedYear, selectedIntegration]);

  const filterProcessesByIntegration = (value) => {
    actions.dashboard.setSelectedIntegration(value);
  };

  const filterProcessesByYear = (year) => {
    actions.dashboard.setSelectedYear(year);
  };

  const disablePickerDates = (date) => {
    if (date.year() > moment().year()) return true;
    if (availableYears.length) {
      return !availableYears.includes(date.year());
    }
    return false;
  };

  return (
    <div>
      <div className="dash-panels">
        <div className="Summaryn">
          <div className="filter-header">
            <div className="filter-header-title">
              Resumen procesos
            </div>
            <div className="process-filters">
              <Select
                style={{ width: 320, marginRight: 10 }}
                onChange={(value) => {
                  filterProcessesByIntegration(value);
                }}
                value={selectedIntegration}
              >
                <Option value={0} key="dash-select-default">Todas las integraciones</Option>
                {integrationList.map((i) => <Option key={`dash-select-${i.name}${i.id}`} value={i.id}>{i.name}</Option>)}
              </Select>

              <DatePicker
                onChange={(data) => {
                  filterProcessesByYear(data || null);
                }}
                picker="year"
                defaultValue={selectedYear}
                disabledDate={disablePickerDates}
              />
            </div>
          </div>
          {processSummary.loading
            ? (
              <div className="processSummary">
                <div className="simple-card">
                  <Skeleton
                    active
                    paragraph={{
                      rows: 1,
                    }}
                  />
                </div>

                <div className="simple-card">
                  <Skeleton
                    active
                    paragraph={{
                      rows: 1,
                    }}
                  />
                </div>

                <div className="simple-card">
                  <Skeleton
                    active
                    paragraph={{
                      rows: 1,
                    }}
                  />
                </div>

                <div className="simple-card">
                  <Skeleton
                    active
                    paragraph={{
                      rows: 1,
                    }}
                  />
                </div>
              </div>
            )
            : (
              <div className="processSummary">
                <div className="simple-card" style={{ background: '#204080' }}>
                  <div className="t">Total procesos</div>
                  <div className="v">{processSummary.TotalProcesses}</div>
                </div>

                <div className="simple-card" style={{ background: '#5fb65f' }}>
                  <div className="t">Finalizados</div>
                  <div className="v">{processSummary.TotalFinished}</div>
                </div>

                <div className="simple-card" style={{ background: '#fd9d49' }}>
                  <div className="t">Cancelados</div>
                  <div className="v">{processSummary.TotalCancelled}</div>
                </div>

                <div className="simple-card" style={{ background: '#df5c5c' }}>
                  <div className="t">Expirados</div>
                  <div className="v">{processSummary.TotalExpired}</div>
                </div>
              </div>
            )}
        </div>
        <div className="process-Summary-charts">
          <LineChart key="dashboard-lien-chart" data={chartData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
