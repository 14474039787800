import { AxiosResponse } from 'axios';
import AdminAxios from '../../utils/axios';

const getIntegrationProcessAPI = (_id: number) => AdminAxios.get(`/sign/process?integration_id=${_id}`);
const getIntegrationAPI = (_id: number) => AdminAxios.get(`/integration/${_id}`);
const getIntegrationSmsAPI = (_id: number) => AdminAxios.get(`/sms?integration=${_id}`);

export {
  getIntegrationProcessAPI,
  getIntegrationSmsAPI,
  getIntegrationAPI,
};
