import React, { useEffect } from 'react';
import {
  message, Form, Input, Tag, Switch, Row, Col,
} from 'antd';
import {
  useHistory, Switch as RouteSwitch, Route, useRouteMatch, useLocation,
} from 'react-router-dom';
import moment from 'moment';
import { useActions, useState } from '../../overmind';
import MultifirmaTable, { FilterTypes } from '../../components/MultifirmaTable/MultifirmaTable';
import MultifirmaForm from '../../components/MultifirmaForm/MultifirmaForm';
import { ChildRoutes, MainRoutes } from '../../config/routes';
import Integration from './Integration';
import './IntegrationList.css';
import IntegrationForm from '../../components/IntegrationForm/IntegrationForm';

const { TextArea } = Input;

const cols = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'integration-name',
    type: FilterTypes.Search,
    width: '20%',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Token',
    dataIndex: 'client_token',
    key: 'client-token',
    width: '25%',
  },
  {
    title: 'Fecha creacion',
    dataIndex: 'creation_date',
    key: 'creation-date',
    align: 'center',
    width: '15%',
    type: FilterTypes.DateRange,
    defaultSortOrder: 'descend',
    sorter: (a, b) => moment(a.creation_date).valueOf() - moment(b.creation_date).valueOf(),
  },
  {
    title: 'Estado',
    dataIndex: 'active',
    key: 'inte-active',
    align: 'center',
    width: '10%',
    type: FilterTypes.Select,
    filterData: {
      table: 'integration',
      dataIndex: 'id',
      data: [{
        text: 'Activa',
        value: 1,
      }, {
        text: 'Inactiva',
        value: 0,
      }],
    },
    render: (estado) => {
      let color;
      if (estado === 1) {
        color = 'green';
      } else {
        color = 'orange';
      }
      return (
        <Tag color={color} key={estado}>
          {estado ? 'Activo' : 'Inactivo'}
        </Tag>
      );
    },
  },
];

const IntegrationList = () => {
  const { path } = useRouteMatch();
  const { integrationList } = useState();
  const history: any = useHistory();
  const actions = useActions();
  const [formVisible, setFormVisible] = React.useState(false);
  const location = useLocation();
  useEffect(() => {
    actions.app.navigateMain({
      ...MainRoutes.integrationList, history: location.key,
    });

    actions.integrationList.resetFilter();
    getIntegratiosTableData();
  }, []);

  const getIntegratiosTableData = async () => {
    const result = await actions.integrationList.getIntegrationList();
    if (result.status === 'error') {
      message.error(`${result.data}`);
    }
  };

  const submitIntegration = (data) => {
    const {
      intName, finUrl, cancelUrl, intCustomData, intIp, intActive, id,
    } = data;
    const integrationData = {
      name: intName,
      url_sign_finish: finUrl,
      allowed_ips: intIp,
      url_sign_cancel: cancelUrl,
      integration_data_json: intCustomData,
      active: intActive ? 1 : 0,
    };
    if (integrationList.formMode === 'create') {
      actions.integrationList.createIntegration(integrationData)
        .then((response) => {
          if (response.status !== 'success') {
            message.error('Error al crear la integracion');
          } else {
            actions.integrationList.setFormVisible(false);
            getIntegratiosTableData();
            message.success('Integracion creada correctamente');
          }
        })
        .catch((error) => message.error(`Error: ${error}`));
    } else {
      actions.integrationList.editIntegration({ ...integrationData, id })
        .then((response) => {
          if (response.status !== 'success') {
            message.error('No se h apodido editar la integracion');
          } else {
            actions.integrationList.setFormVisible(false);
            getIntegratiosTableData();
            message.success(`Integracion editada correctamente - ${response.data}`);
          }
        })
        .catch((error) => message.error(`Error: ${error}`));
    }
  };

  const rowClicked = (record) => {
    const route = ChildRoutes.integration;
    // actions.integration.setIntegrationId(record);
    history.push(`/integration/${record.id}`);
  };

  const onSearch = (selectedKeys, confirm, dataIndex, type): void => {
    if (type === FilterTypes.DateRange) {
      actions.integrationList.addFilter({
        dataIndex: 'from',
        value: selectedKeys[0]?.format('YYYY-MM-DD'),
      });
      actions.integrationList.addFilter({
        dataIndex: 'to',
        value: selectedKeys[1]?.format('YYYY-MM-DD'),
      });
    } else {
      actions.integrationList.addFilter({
        dataIndex,
        value: selectedKeys[0],
      });
    }
    getIntegratiosTableData();
    confirm();
  };

  const onReset = (dataIndex, type) => {
    if (type === FilterTypes.DateRange) {
      actions.integrationList.addFilter({
        dataIndex: 'from',
        value: null,
      });
      actions.integrationList.addFilter({
        dataIndex: 'to',
        value: null,
      });
    } else {
      actions.integrationList.addFilter({
        dataIndex,
        value: null,
      });
    }
    getIntegratiosTableData();
  };

  const onEdit = (record) => {
    actions.integrationList.setFormToEdit(record);
  };

  const canAdd = actions.app.canAccess({ path: '/integration', method: 'POST' });
  const canEdit = actions.app.canAccess({ path: '/integration', method: 'PATCH' });

  return (
    <RouteSwitch>
      <Route exact path={path}>
        <div className="integration-list-page">
          <MultifirmaTable
            data={integrationList.data}
            cols={cols}
            onAdd={canAdd ? () => actions.integrationList.setFormVisible(true) : null}
            handleSearch={onSearch}
            handleReset={onReset}
            title="Lista de integraciones"
            exportData
            handleView={rowClicked}
            handleEdit={canEdit ? onEdit : null}
            loading={integrationList.loading}
          />
          <IntegrationForm submitIntegration={submitIntegration} />
        </div>
      </Route>
      <Route path={`${path}/:id`}>
        <Integration />
      </Route>
    </RouteSwitch>
  );
};

export default IntegrationList;
