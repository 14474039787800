/* eslint-disable no-unused-expressions */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-param-reassign */
import { AsyncAction, Action } from 'overmind';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { IResponse } from '../../interfaces/common';

export const initialize: AsyncAction<void, void> = async ({ state, effects }) => {
  const { selectedIntegration, selectedYear } = state.smsList;
  try {
    const filters = [];

    selectedIntegration && filters.push(`integration_id=${selectedIntegration}`);
    selectedYear && filters.push(`year=${selectedYear.year()}`);

    state.smsList.loading = true;

    const auxState: any = {};

    await Promise.all([
      effects.smsList.getSmsChartAPI(filters).then((chartResponse) => {
        auxState.chartData = chartResponse.data.formattedData;
        auxState.years = chartResponse.data.years;
      }),
      effects.smsList.getSmsListAPI(filters).then((tableResponse) => {
        auxState.data = tableResponse.data;
      }),
    ]);

    const ay = auxState.years.map(
      (year) => parseInt(year.yearsAvailable, 10),
    );

    state.smsList = {
      ...state.smsList,
      chartData: auxState.chartData,
      data: auxState.data,
      availableYears: ay,
      loading: false,
    };
  } catch (error) {
    state.smsList.loading = false;
  }
};

export const getIntegrations: AsyncAction<void, IResponse> = async (
  { state, effects },
) => {
  try {
    const response = await effects.dashboard.getIntegrationsAPI();
    if (response.status === 200) {
      state.smsList.integrationList = response.data;
      return {
        status: 'success',
        data: response.data,
      };
    }
    return {
      status: 'error',
      data: response.data,
    };
  } catch (error) {
    return {
      status: 'error',
      data: error,
    };
  }
};

export const getSmsList: AsyncAction<void, IResponse> = async ({ effects, state }) => {
  try {
    state.smsList.loading = true;
    const response = await effects.smsList.getSmsListAPI(state.smsList.filters);

    if (response.status === 200) {
      state.smsList.data = response.data;
      state.smsList.loading = false;
      return {
        status: 'success',
        data: response.data,
      };
    }
    return {
      status: 'error',
      data: response.data,
    };
  } catch (error) {
    return {
      status: 'error',
      data: error,
    };
  }
};

export const setChartData: AsyncAction = async ({ state }) => {
  const smsList = state.smsList.data;
  const convertedArray = moment.monthsShort().map((key, index) => ({
    month: key,
    Success: smsList.reduce((sum, n) => {
      if (n.send_date && moment(n.send_date).month() === index && n.sent === 1) {
        return sum + 1;
      }
      return sum;
    }, 0),
    Error: smsList.reduce((sum, n) => {
      if (n.send_date && moment(n.send_date).month() === index && n.sent === 0
        || n.sign_action && moment(n.processStartDatetime).month() === index && n.sent === 0) {
        return sum + 1;
      }
      return sum;
    }, 0),
    Pending: smsList.reduce((sum, n) => {
      if (!n.sign_action && moment(n.processStartDatetime).month() === index) {
        return sum + 1;
      }
      return sum;
    }, 0),
  }));
  state.smsList.chartData = convertedArray;
};

export const setAvailableYears: Action = ({ state }) => {
  let allYears = [];
  state.smsList.data.forEach((sms) => {
    const uniqueYears = new Set([
      moment(sms.send_date).year(),
    ]);

    allYears = [...allYears, ...Array.from(uniqueYears)];
  });
  const uniqueAllYears = _.compact(Array.from(new Set(allYears)).sort((a, b) => a - b));
  state.smsList.availableYears = uniqueAllYears;
};

export const setSelectedIntegration: Action<number> = ({ state }, integration_id: number) => {
  state.smsList.selectedIntegration = integration_id;
};

export const setSelectedYear: Action<Moment> = ({ state }, year: Moment) => {
  state.smsList.selectedYear = year;
};

export const resetFilter: Action<void, void> = ({ state }) => {
  state.smsList.filters = {};
};

export const addFilter: Action<{}, void> = (
  { state }, filter: any,
) => {
  state.smsList.filters[`${filter.dataIndex}`] = filter.value;
};
