import AdminAxios from '../../utils/axios';

const loginAPI = async (user) => AdminAxios.post('/user/login', {
  username: user.username,
  password: user.password,
});

const createUserAPI = (data) => AdminAxios.post('/user', data);
const modifyUserAPI = (data) => AdminAxios.patch(`/user/${data.id}`, data);

export {
  loginAPI,
  createUserAPI,
  modifyUserAPI,
};
