import { UserActivityType } from '../../interfaces';

export const data: UserActivityType[] = [];

export const state: any = {
  data,
  filters: {},
  loading: true,
  error: null,
};
