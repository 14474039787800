export const state: any = {
  showLogin: false,
  navigation: {
    currentScreen: {

    },
    breadCrumb: [],
  },
  navigationMemory: [],
  user: {
    id: 0,
  },
  stored: false,
  loading: true,
  accessibles: {},
  theme: 'light',
};
