import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useOvermind, useActions } from './overmind';
import AdminRouter from './Router';
import './App.css';

const App = () => {
  const { state } = useOvermind();
  const actions = useActions();

  useEffect(() => {
    document.title = 'Multifirma Admin';
    actions.app.authenticate();
  }, []);

  return (
    <div className={`App theme-${state.app.theme}`}>
      { state.app.loading
        ? (
          <div className="loading-main">
            <Spin />
          </div>
        )
        : <AdminRouter />}
    </div>
  );
};

export default App;
