/* eslint-disable no-param-reassign */
import { Action, AsyncAction } from 'overmind';
import { IResponse } from '../../interfaces/common';

export const getUsers: AsyncAction<void, IResponse> = async ({ effects, state }) => {
  state.userList.loading = true;
  const response = await effects.userList.getUsersAPI(state.userList.filters);

  if (response.status === 200 && response.data.length > 0) {
    state.userList = {
      ...state.userList,
      data: response.data,
      loading: false,
    };
    return {
      status: 'success',
      data: response.data,
    };
  }
  state.userList = {
    ...state.userList,
    error: response.data,
    loading: false,
  };
  return {
    status: 'error',
    data: response.data,
  };
};

export const clearError: Action<void, void> = ({ state }) => {
  state.userList.error = null;
};

export const resetFilter: Action<void, void> = ({ state }) => {
  state.userList.filters = {};
};

export const addFilter: Action<{}, void> = (
  { state }, filter: any,
) => {
  state.userList.filters[`${filter.dataIndex}`] = filter.value;
};

export const deleteUser: AsyncAction<number, IResponse> = async (
  { effects, state },
  id: number,
) => {
  const response = await effects.userList.deleteUserAPI(id);

  if (response.status === 200) {
    return {
      status: 'success',
      data: response.data,
    };
  }
  return {
    status: 'error',
    data: response.data,
  };
};

export const setFormToEdit: Action<{}, void> = ({ state }, record: any) => {
  state.userList.formData = {
    id: record.id,
    userlist_username: record.username,
    userlist_password: record.password,
    userlist_role: record.role_id,
  };
  state.userList.formMode = 'edit';
  state.userList.formVisible = true;
};

export const setFormVisible: Action<boolean, void> = ({ state }, visible) => {
  state.userList.formMode = 'create';
  state.userList.formVisible = visible;
};
