import AdminAxios from '../../utils/axios';

const loginAPI = async (user) => AdminAxios.post('/user/login', {
  username: user.username,
  password: user.password,
});

const authenticateAPI = async () => AdminAxios.post('/user/auth');

const getPermissionsAPI = async (userId) => AdminAxios.post(`/user/permissions/${userId}`);
export {
  loginAPI,
  authenticateAPI,
  getPermissionsAPI,
};
