import { IConfig } from 'overmind';
import {
  createHook,
  createActionsHook,
  createStateHook,
  createEffectsHook,
} from 'overmind-react';
import { namespaced, merge } from 'overmind/config';
import { state } from './state';
import * as actions from './actions';
import * as user from './user';
import * as app from './app';
import * as userList from './userList';
import * as smsList from './smsList';
import * as integrationList from './integrationList';
import * as integration from './integration';
import * as dashboard from './dashboard';
import * as processList from './processList';
import * as userActivityList from './userActivityList';
import * as process from './process';

export const config = merge({
  state,
  actions,
},
namespaced({
  user,
  app,
  userList,
  smsList,
  integrationList,
  integration,
  dashboard,
  processList,
  process,
  userActivityList,
}));

declare module 'overmind' {
  // eslint-disable-next-line no-unused-vars
  interface Config extends IConfig<typeof config> { }
}

export const useOvermind = createHook<typeof config>();
export const useState = createStateHook<typeof config>();
export const useActions = createActionsHook<typeof config>();
export const useEffects = createEffectsHook<typeof config>();
