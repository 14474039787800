/* eslint-disable no-param-reassign */
import { Action, AsyncAction } from 'overmind';
import { IntegrationType } from '../../interfaces/Integration';
import { IResponse } from '../../interfaces/common';

export const setIntegrationId: Action<IntegrationType> = ({ state }, integration) => {
  state.integration.integration.id = integration.id;
};

export const getIntegration: AsyncAction<number, IResponse> = async (
  { state, effects }, integrationId,
) => {
  try {
    const response = await effects.integration.getIntegrationAPI(integrationId);
    const { integration } = state.integration;
    if (response.status === 200) {
      state.integration.integration = { ...integration, ...response.data.data };
      return {
        status: 'success',
        data: response.data,
      };
    }
    return {
      status: 'error',
      data: response.data,
    };
  } catch (error) {
    return {
      status: 'error',
      data: error,
    };
  }
};

export const getIntegrationProcess: AsyncAction<number, IResponse> = async (
  { state, effects }, integrationId,
) => {
  try {
    const response = await effects.integration.getIntegrationProcessAPI(integrationId);
    if (response.status === 200) {
      state.integration.processStats = response.data;
      return {
        status: 'success',
        data: response.data,
      };
    }
    return {
      status: 'error',
      data: response.data,
    };
  } catch (error) {
    return {
      status: 'error',
      data: error,
    };
  }
};
