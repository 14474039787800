import AdminAxios from '../../utils/axios';

const getIntegrationListAPI = (filters) => AdminAxios.get(
  `/integration?${Object.keys(filters).map((f) => (filters[f] ? `${f}=${filters[f]}` : '')).join('&')}`,
);
const postIntegrationAPI = (integrationData) => AdminAxios.post('/integration', integrationData);
const patchIntegrationAPI = (id, integrationData) => AdminAxios.patch(`/integration/${id}`, integrationData);
const getIntegrationAPI = (id) => AdminAxios.get(`/integration/${id}`);

export {
  getIntegrationListAPI,
  postIntegrationAPI,
  getIntegrationAPI,
  patchIntegrationAPI,
};
