/* eslint-disable no-param-reassign */
import { AsyncAction, Action } from 'overmind';
import { IResponse } from '../../interfaces/common';

export const getProcessList: AsyncAction<void, IResponse> = async ({ effects, state }) => {
  state.processList.loading = true;
  const response = await effects.processList.getProcessListAPI(state.processList.filters);
  if (response.status === 200) {
    state.processList.loading = false;
    state.processList.data = response.data.map((process) => {
      let processState = 'active';
      if (process.expire_datetime) processState = 'expired';
      else if (process.cancel_datetime) processState = 'cancelled';
      else if (process.finish_datetime) processState = 'finished';
      return {
        ...process,
        processState,
      };
    });
    return {
      status: 'success',
      data: response.data,
    };
  }
  return {
    status: 'error',
    data: response.data,
  };
};

export const resetFilter: Action<void, void> = ({ state }) => {
  state.processList.filters = {};
};

export const addFilter: Action<{}, void> = (
  { state }, filter: any,
) => {
  state.processList.filters[`${filter.dataIndex}`] = filter.value;
};
