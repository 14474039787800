import { UserType } from '../../interfaces/User';

export const data: UserType[] = [];

export const state: any = {
  data,
  filters: {},
  formMode: 'create',
  formData: {},
  loading: true,
  formVisible: false,
  error: null,
};
