/* eslint-disable no-param-reassign */
import { AsyncAction, Action } from 'overmind';
import { UserType, Common } from '../../interfaces';
import { IResponse } from '../../interfaces/common';

export const createUser: AsyncAction<UserType, IResponse> = async (
  { effects, state }, user: UserType,
) => {
  const response = await effects.user.createUserAPI(user);
  state.user = response.data;
  return {
    status: 'success',
    data: response.data,
  };
};

export const modifyUser: AsyncAction<UserType, IResponse> = async (
  { effects, state }, user: UserType,
) => {
  const response = await effects.user.modifyUserAPI(user);
  state.user = response.data;
  return {
    status: 'success',
    data: response.data,
  };
};
