import React, { useEffect } from 'react';
import {
  InteractionOutlined,
  MailOutlined,
  ProfileOutlined,
  ReloadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  Tooltip, Progress, message, Descriptions,
} from 'antd';
import moment from 'moment';
import { ChildRoutes } from '../../config/routes';
import { useActions, useState } from '../../overmind';
import MultifirmaTable from '../../components/MultifirmaTable/MultifirmaTable';

import './Process.css';

export default function Process() {
  const history: any = useHistory();
  const actions = useActions();
  const { process } = useState();
  const { id }: any = useParams();
  const location = useLocation();

  useEffect(() => {
    actions.process.getSingleProcess(id).then(() => {
      actions.app.pushBread({
        ...ChildRoutes.process,
        name: `${id}`,
        path: `/process/${id}`,
        history: location.key,
      });
    });
    getSigners(id);
    getRetries(id);
  }, []);

  const signerCols = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'nombre',
      width: 150,
    },
    {
      title: 'DNI',
      dataIndex: 'dni',
      key: 'dni',
    },
    {
      title: 'Telefono',
      dataIndex: 'phone',
      key: 'telefono',
    },
    {
      title: 'Firmado',
      dataIndex: 'signedAt',
      key: 'firmado',
    },
  ];

  const getRetries = async (processId: number) => {
    const result = await actions.process.getProcessRetries(processId);
    if (result.status === 'error') {
      message.error(`${result.data}`);
    }
  };

  const getSigners = async (processId: number) => {
    const result = await actions.process.getProcessSigners(processId);
    if (result.status === 'error') {
      message.error(`${result.data}`);
    }
  };

  const getProcessStatus = () => {
    let status = 'Activo';
    if (process.expire_datetime) status = 'Expirado';
    else if (process.finish_datetime) status = 'Finalizado';
    else if (process.cancel_datetime) status = 'Cancelado';
    return status;
  };

  const getProgressPercent = (): number => {
    const signedSigners = process.signers?.filter((signer) => signer.signedAt);
    return Math.ceil((signedSigners?.length / process.TotalSigners) * 100);
  };

  const getProgressNumber = (): number => {
    const signedSigners = process.signers?.filter((signer) => signer.signedAt);
    return signedSigners?.length ?? 0;
  };

  const getSigningPercent = (): number => Math.ceil((1 / process.TotalSigners) * 100);

  const getStatusName = (): string => {
    if (!process.retries || process.retries?.length === 0) return '';
    switch ((process.retries[process.retries?.length - 1]).statusName) {
      case 'success':
        return 'Enviado';
      case 'pending':
        return 'Pendiente';
      case 'error':
        return 'Error';
      default:
        return '';
    }
  };

  return (
    <div className="integration-container">
      <div className="integration-info">
        <Descriptions title="Informacion proceso" bordered layout="vertical" column={4}>
          <Descriptions.Item label="Fecha de inicio" span={2}>
            {process.process_start_datetime}
          </Descriptions.Item>
          <Descriptions.Item label="Días hasta expiración" span={2}>
            {process.process_max_duration_days}
          </Descriptions.Item>
          <Descriptions.Item label="Fecha de expiración" span={2}>
            {moment(process.process_start_datetime).add(process.process_max_duration_days, 'd').format('YYYY-MM-DD')}
          </Descriptions.Item>
          <Descriptions.Item label="SMS enviados" span={2}>
            {process.SentSms ?? 0}
          </Descriptions.Item>
          <Descriptions.Item label="Ultima vez firmado" span={2}>
            {process.last_sign_datetime ?? 'Sin firmar'}
          </Descriptions.Item>
          <Descriptions.Item label="Intentos de envio" span={2}>
            {process.retries?.length ?? 0}
          </Descriptions.Item>
          <Descriptions.Item label="Progreso del proceso de firma" span={4}>
            <Tooltip title={`
              ${getProgressNumber()} Firmados / 
              ${process.signers?.length - getProgressNumber() >= 1 ? 1 : 0} Firmando / 
              ${process.TotalSigners - getProgressNumber() - 1 >= 0 ? process.TotalSigners - getProgressNumber() - 1 : 0} Por firmar`}
            >
              <Progress className="card-progress" percent={getProgressPercent() + getSigningPercent() <= 100 ? getProgressPercent() + getSigningPercent() : 100} success={{ percent: getProgressPercent() }} />
            </Tooltip>
          </Descriptions.Item>
        </Descriptions>

        <div className="signers-table-container">
          <div className="signers-table-title">Firmantes</div>
          <MultifirmaTable
            cols={signerCols}
            loading={process.loading}
            data={process.signers}
            pagination={false}
            scroll={{ y: 260 }}
          />
        </div>
      </div>
    </div>
  );
}
