import {
  Col, Form, Input, Row, Switch,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { useState, useActions } from '../../overmind';
import MultifirmaForm from '../MultifirmaForm/MultifirmaForm';

const IntegrationForm = (props: any) => {
  const { submitIntegration } = props;
  const actions = useActions();
  const { integrationList } = useState();
  return (
    <MultifirmaForm
      title="Crear integracion"
      onSubmit={submitIntegration}
      data={integrationList.formData}
      mode={integrationList.formMode}
      visible={integrationList.formVisible}
      onCancel={() => { actions.integrationList.setFormVisible(false); }}
    >
      <Row align="bottom">
        <Col flex="auto" style={{ marginRight: 10 }}>
          <Form.Item
            name="intName"
            label="Nombre"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input
              placeholder="Nombre"
            />
          </Form.Item>
        </Col>
        <Form.Item
          name="intActive"
          valuePropName="checked"
          label=""
        >
          <Switch
            checkedChildren="Integracion activa "
            unCheckedChildren="Integracion inactiva"
            defaultChecked
          />
        </Form.Item>
      </Row>
      <Form.Item
        name="finUrl"
        label="URL fin porceso"
        rules={[{ required: true, message: 'Please input your Username!' }]}
      >
        <Input
          placeholder="https://domain.com/path"
        />
      </Form.Item>
      <Form.Item
        name="cancelUrl"
        label="URL cancelacion proceso"
        rules={[{ required: true, message: 'Please input your Username!' }]}
      >
        <Input
          placeholder="https://domain.com/path"
        />
      </Form.Item>
      <Form.Item
        name="intCustomData"
        label="Datos para la resupesta del proceso"
      >
        <TextArea rows={4} placeholder="{ ... }" />
      </Form.Item>
      <Form.Item
        name="intIp"
        label="IP-s permitidas"
        extra="Introducir IP-s separadas por coma"
        rules={[{ required: true, message: 'Please input your Username!' }]}
      >
        <TextArea rows={2} placeholder="*" />
      </Form.Item>
    </MultifirmaForm>
  );
};

export default IntegrationForm;
