import React, { useEffect, useState as useReactState } from 'react';
import './UserList.css';
import {
  Tag,
  Form,
  Input,
  Select,
  message,
} from 'antd';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useActions, useState } from '../../overmind';
import MultifirmaTable, { FilterTypes } from '../../components/MultifirmaTable/MultifirmaTable';
import MultifirmaForm from '../../components/MultifirmaForm/MultifirmaForm';
import { MainRoutes } from '../../config/routes';

const UserList = () => {
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'username',
      key: 'username',
      type: FilterTypes.Search,
      width: '30%',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Ultima conexión',
      dataIndex: 'lastConnection',
      key: 'lastConnection',
      type: FilterTypes.DateRange,
      defaultSortOrder: 'descend',
      width: '30%',
      sorter: (a, b) => new Date(a.lastConnection).getTime() - new Date(b.lastConnection).getTime(),
    },
    {
      title: 'Rol',
      dataIndex: 'rolename',
      align: 'center',
      width: '10%',
      key: 'role',
      render: (rolename) => {
        const rn = rolename;
        let color;
        if (rn === 'admin') {
          color = 'geekblue';
        } else if (rn === 'user') {
          color = 'green';
        } else {
          color = 'orange';
        }
        return (
          <Tag color={color} key={rn}>
            {rn.toUpperCase()}
          </Tag>
        );
      },
    },
  ];
  const actions = useActions();
  const { userList } = useState();
  const { Option } = Select;
  const history: any = useHistory();
  const location = useLocation();

  useEffect(() => {
    actions.app.navigateMain({
      ...MainRoutes.userList,
      history: location.key,
    });
    getUserList();
  }, []);

  useEffect(() => {
    if (userList.error) {
      message.error(userList.error?.message);
      actions.userList.clearError();
    }
  }, [userList.error]);

  const getUserList = async () => {
    const result = await actions.userList.getUsers();
    if (result.status === 'error') {
      // message.error(`${result.data}`);
    }
  };

  const onSearch = (selectedKeys, confirm, dataIndex, type): void => {
    if (type === FilterTypes.DateRange) {
      actions.userList.addFilter({
        dataIndex: 'from',
        value: selectedKeys[0]?.format('YYYY-MM-DD'),
      });
      actions.userList.addFilter({
        dataIndex: 'to',
        value: selectedKeys[1]?.format('YYYY-MM-DD'),
      });
    } else {
      actions.userList.addFilter({
        dataIndex,
        value: selectedKeys[0],
      });
    }
    getUserList();
    confirm();
  };

  const onReset = (dataIndex, type) => {
    if (type === FilterTypes.DateRange) {
      actions.userList.addFilter({
        dataIndex: 'from',
        value: null,
      });
      actions.userList.addFilter({
        dataIndex: 'to',
        value: null,
      });
    } else {
      actions.userList.addFilter({
        dataIndex,
        value: null,
      });
    }
    getUserList();
  };

  const sendUser = async (data) => {
    const {
      userlist_username, userlist_password, userlist_role,
    } = data;

    const createUserData = {
      username: userlist_username,
      password: CryptoJS.SHA1(userlist_password).toString(),
      role_id: userlist_role,
    };
    const modifyUserData = {
      id: data.id,
      username: userlist_username,
      password: userlist_password,
      role_id: userlist_role,
    };

    if (userList.formMode === 'create') {
      actions.user.createUser(createUserData)
        .then((response) => {
          if (response.status !== 'success') {
            message.error('Algo no ha ocurrido como debería. :(');
          }
          actions.userList.setFormVisible(false);
          message.success('Usuario creado correctamente :)');
          getUserList();
        })
        .catch((error) => message.error(`Error: ${error}`));
    } else {
      actions.user.modifyUser(modifyUserData)
        .then((response) => {
          if (response.status !== 'success') {
            message.error('Algo no ha ocurrido como debería. :(');
          }
          actions.userList.setFormVisible(false);
          message.success('Usuario modificado correctamente :)');
          getUserList();
        })
        .catch((error) => message.error(`Error: ${error}`));
    }
  };

  const submitUser = (data) => {
    sendUser(data);
  };

  return (
    <>
      <MultifirmaTable
        cols={columns}
        title="Lista de usuarios"
        data={userList.data}
        loading={userList.loading}
        handleSearch={onSearch}
        handleReset={onReset}
        handleEdit={(record) => {
          actions.userList.setFormToEdit(record);
        }}
        handleDelete={(record) => {
          actions.userList.deleteUser(record.id)
            .then((response) => {
              if (response.status === 'success') {
                actions.userList.getUsers();
                message.success('Usuario eliminado correctamente :)');
              } else {
                message.error('NUsuario eliminado correctamente :)');
              }
            });
        }}
        onAdd={() => {
          actions.userList.setFormVisible(true);
        }}
      />
      <MultifirmaForm
        title="Usuario"
        onSubmit={submitUser}
        visible={userList.formVisible}
        onCancel={() => { actions.userList.setFormVisible(false); }}
        mode={userList.formMode}
        data={userList.formData}
      >
        <Form.Item
          name="userlist_username"
          label="Nombre"
          rules={[{ required: true, message: 'Introduce un nombre de usuario.' }]}
        >
          <Input
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="userlist_password"
          label="Contraseña"
          rules={
            [
              {
                required: true,
                message: 'Por favor, introduce la contraseña.',
              },
              {
                min: 8,
                max: 30,
                message: 'La contraseña debe tener entre 8 y 30 caracteres.',
              },
            ]
          }
        >
          <Input.Password
            type="password"
            placeholder="* * * * *"
            iconRender={(passVisible) => (passVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item
          name="userlist_role"
          label="Rol de usuario"
          rules={[{ required: true, message: 'Por favor selecciona un rol!' }]}
        >
          <Select placeholder="Role">
            <Option value={1}>VIEWER</Option>
            <Option value={2}>USER</Option>
            <Option value={3}>ADMIN</Option>
          </Select>
        </Form.Item>
      </MultifirmaForm>
    </>
  );
};
export default UserList;
