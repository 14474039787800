import AdminAxios from '../../utils/axios';

const postProcessAPI = (processData) => AdminAxios.post('/sign/process', processData);
const getProcessAPI = (id) => AdminAxios.get(`/sign/process/${id}`);
const getProcessSignersAPI = (processId) => AdminAxios.get(`/sign/process/signers/${processId}`);
const getProcessRetriesAPI = (processId) => AdminAxios.get(`/sign/process/retries/${processId}`);

export {
  postProcessAPI,
  getProcessAPI,
  getProcessSignersAPI,
  getProcessRetriesAPI,
};
