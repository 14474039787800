/* eslint-disable no-mixed-operators */
import React, { useEffect } from 'react';
import {
  message,
  Spin,
  Tag,
  Select,
  DatePicker,
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import MultifirmaTable, { FilterTypes } from '../../components/MultifirmaTable/MultifirmaTable';
import BarChart from '../../components/BarChart/BarChart';
import { useState, useActions } from '../../overmind';
import './SmsStats.css';
import { MainRoutes } from '../../config/routes';
import { initialize } from '../../overmind/smsList/actions';

const { Option } = Select;

const smsColumns = [
  {
    title: 'Integracion',
    dataIndex: 'integration_name',
    key: 'integration-name',
    type: FilterTypes.Select,
    width: '25%',
    sorter: (a, b) => a.integration_name.localeCompare(b.integration_name),
    filterData: {
      table: 'integration',
      dataIndex: 'integration_id',
      data: [{
        text: 'e-coordina-test',
        value: 1,
      }, {
        text: 'Pruebas Niko SA',
        value: 2,
      }, {
        text: 'Pruebas',
        value: 3,
      }],
    },
  },
  {
    title: 'Firmante',
    dataIndex: 'signer_name',
    key: 'signer-name',
    sorter: (a, b) => a.signer_name.localeCompare(b.signer_name),
    width: '25%',
  },
  {
    title: 'DNI',
    dataIndex: 'dni',
    key: 'signer-code',
    sorter: (a, b) => a.dni.localeCompare(b.dni),
    width: '10%',
  },
  {
    title: 'Telefono',
    dataIndex: 'signer_phone',
    key: 'signer-phone',
    width: '10%',
  },
  {
    title: 'Enviado',
    dataIndex: 'sent',
    key: 'sent',
    align: 'center',
    width: '15%',
    render: (sent) => {
      if (sent) {
        return (
          <Tag color="green" key={sent}>
            Enviado
          </Tag>
        );
      }
      return (
        <Tag color="red" key={sent}>
          No enviado
        </Tag>
      );
    },
    filters: [
      { text: 'Enviado', value: 1 },
      { text: 'Rechazado', value: 0 },
    ],
  },
  {
    title: 'Fecha envio',
    dataIndex: 'send_date',
    key: 'send-date',
    width: '15%',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.send_date).getTime() - new Date(b.send_date).getTime(),
  },
];

const SmsStats = () => {
  const state = useState();
  const actions = useActions();
  const history: any = useHistory();
  const location = useLocation();
  useEffect(() => {
    actions.app.navigateMain({
      ...MainRoutes.smsList,
      history: location.key,
    });
    actions.smsList.initialize();
    actions.smsList.getIntegrations();
    // getSmsTableData();
  }, []);

  useEffect(() => {
    actions.smsList.initialize();
  }, [state.smsList.selectedYear, state.smsList.selectedIntegration]);

  const getSmsTableData = async () => {
    actions.smsList.initialize();
  };

  const onSearch = (selectedKeys, confirm, dataIndex, type) => {

  };

  const disablePickerDates = (date) => {
    if (date.year() > moment().year()) return true;
    if (state.smsList.availableYears.length) {
      return !state.smsList.availableYears.includes(date.year());
    }
    return false;
  };

  const filterSmsByYear = (year) => {
    actions.smsList.setSelectedYear(year);
  };

  const filterSmsByIntegration = (value) => {
    actions.smsList.setSelectedIntegration(value);
  };

  const onSelectSearch = (selectedKeys, confirm, dataIndex, table) => {
    actions.smsList.addFilter({
      dataIndex,
      value: selectedKeys.join(','),
    });
    getSmsTableData();
    confirm();
  };

  const onReset = (dataIndex, type) => {
    if (type === FilterTypes.DateRange) {
      actions.smsList.addFilter({
        dataIndex: 'from',
        value: null,
      });
      actions.smsList.addFilter({
        dataIndex: 'to',
        value: null,
      });
    } else {
      actions.smsList.addFilter({
        dataIndex,
        value: null,
      });
    }
    getSmsTableData();
  };

  return (
    <div className="sms-stats-pages">
      <div className="filter-header">
        <div className="filter-header-title">
          Resumen SMS
        </div>
        <div className="process-filters">
          <Select
            style={{ width: 320, marginRight: 10 }}
            onChange={(value) => {
              filterSmsByIntegration(value);
            }}
            value={state.smsList.selectedIntegration}
          >
            <Option value={0} key="dash-select-default">Todas las integraciones</Option>
            {state.smsList.integrationList.map((i) => <Option key={`dash-select-${i.name}${i.id}`} value={i.id}>{i.name}</Option>)}
          </Select>

          <DatePicker
            onChange={(data) => {
              filterSmsByYear(data || null);
            }}
            picker="year"
            defaultValue={state.smsList.selectedYear}
            disabledDate={disablePickerDates}
          />
        </div>
      </div>
      <div className="sms-grafic-container">
        <div className="sms-grafic">
          {!state.smsList.loading ? (
            <BarChart
              data={state.smsList.chartData}
              keys={[
                'Success',
                'Error',
                'Pending',
              ]}
              key="sms-chart-month"
              indexBy="month"
              axisLeft="Cantidad"
              axisBottom="Meses"
            />
          ) : <Spin />}
        </div>
      </div>
      <MultifirmaTable
        key="sms-stats-table"
        data={state.smsList.data}
        loading={state.smsList.loading}
        cols={smsColumns}
        title="Lista de SMS"
        handleSearch={onSearch}
        handleSelectSearch={onSelectSearch}
        handleReset={onReset}
      />
    </div>
  );
};

export default SmsStats;
