import React, { useEffect, useState as useReactState } from 'react';
import {
  Tag,
  message,
  Popover,
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import ReactJsonView from 'react-json-view';
import { useActions, useState } from '../../overmind';
import MultifirmaTable, { FilterTypes } from '../../components/MultifirmaTable/MultifirmaTable';
import { MainRoutes } from '../../config/routes';

const UserActivityList = () => {
  const columns = [
    {
      title: 'Usuario',
      width: '20%',
      dataIndex: 'username',
      type: FilterTypes.Search,
      key: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Fecha',
      dataIndex: 'action_date',
      key: 'userActionDate',
      type: FilterTypes.DateRange,
      width: '30%',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.action_date).valueOf() - moment(b.action_date).valueOf(),
    },
    {
      title: 'Ruta',
      width: '20%',
      type: FilterTypes.Search,
      dataIndex: 'route',
      key: 'userActionPath',
      sorter: (a, b) => a.route.localeCompare(b.route),
    },
    {
      title: 'Metodo',
      dataIndex: 'method',
      align: 'center',
      width: '10%',
      key: 'userActionMethod',
      render: (methodParameter) => {
        const method = methodParameter;
        let color;
        if (method === 'POST') {
          color = 'geekblue';
        } else if (method === 'PATCH') {
          color = 'orange';
        } else if (method === 'DELETE') {
          color = 'red';
        }
        return (
          <Tag color={color} key={method}>
            {method?.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Data',
      align: 'center',
      dataIndex: 'action_data',
      key: 'userActionData',
      width: '30%',
      ellipsis: true,
      render: (data) => (
        <div style={{ cursor: 'pointer' }}>
          <Popover
            trigger="click"
            placement="bottomLeft"
            content={(
              <ReactJsonView
                style={
                  {
                    width: '370px',
                    height: '200px',
                    overflow: 'auto',
                  }
                }
                src={JSON.parse(data)}
              />
            )}
            title="Request data"
          >
            {data}
          </Popover>
        </div>
      ),
    },
  ];
  const actions = useActions();
  const { userActivityList } = useState();
  const history: any = useHistory();
  const location = useLocation();

  useEffect(() => {
    actions.app.navigateMain({
      ...MainRoutes.activityList,
      history: location.key,
    });
    getUserActivityList();
  }, []);

  useEffect(() => {
    if (userActivityList.error) {
      message.error(userActivityList.error?.message || 'error');
      actions.userActivityList.clearError();
    }
  }, [userActivityList.error]);

  const onSearch = (selectedKeys, confirm, dataIndex, type): void => {
    if (type === FilterTypes.DateRange) {
      actions.userActivityList.addFilter({
        dataIndex: 'from',
        value: selectedKeys[0]?.format('YYYY-MM-DD'),
      });
      actions.userActivityList.addFilter({
        dataIndex: 'to',
        value: selectedKeys[1]?.format('YYYY-MM-DD'),
      });
    } else {
      actions.userActivityList.addFilter({
        dataIndex,
        value: selectedKeys[0],
      });
    }
    getUserActivityList();
    confirm();
  };

  const onReset = (dataIndex, type) => {
    if (type === FilterTypes.DateRange) {
      actions.userActivityList.addFilter({
        dataIndex: 'from',
        value: null,
      });
      actions.userActivityList.addFilter({
        dataIndex: 'to',
        value: null,
      });
    } else {
      actions.userActivityList.addFilter({
        dataIndex,
        value: null,
      });
    }
    getUserActivityList();
  };

  const getUserActivityList = async () => {
    const result = await actions.userActivityList.getUserActivityList();
    if (result.status === 'error') {
      // message.error(`${result.data}`);
    }
  };

  return (
    <>
      <MultifirmaTable
        cols={columns}
        title="Lista de actividad"
        data={userActivityList.data}
        handleSearch={onSearch}
        handleReset={onReset}
        loading={userActivityList.loading}
      />
    </>
  );
};
export default UserActivityList;
