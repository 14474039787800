import AdminAxios from '../../utils/axios';

const getSmsListAPI = (filters) => {
  const query = `/sms?${filters.map((f) => (filters ? `${f}` : '')).join('&')}`;
  return AdminAxios.get(query);
};

const getSmsChartAPI = (filters) => {
  const query = `/sms/chart?${filters.map((f) => (filters ? `${f}` : '')).join('&')}`;
  return AdminAxios.get(query);
};

export {
  getSmsListAPI,
  getSmsChartAPI,
};
