/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState, useRef } from 'react';
import {
  Table, Button, Select, Input, Space, DatePicker, Checkbox, Popconfirm,
} from 'antd';
import {
  PlusOutlined,
  FilterOutlined,
  SearchOutlined,
  CalendarOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import './MultifirmaTable.css';

const { Option } = Select;
const { Column } = Table;
const { RangePicker } = DatePicker;

enum FilterTypes {
  Search,
  DateRange,
  Select
}

type PropTableHeader = {
  onAdd?: () => void,
  title?: string,
  exportData?: boolean,
}

const TableHeader = ({
  onAdd, title, exportData,
}: PropTableHeader) => {
  if (!onAdd && !title && !exportData) return null;

  return (
    <div className="table-header">
      {title ? (<div className="table-title">{title}</div>) : null}
      <div className="table-tools">
        {[onAdd ? (
          <Button
            ghost
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => { onAdd(); }}
          />
        ) : null,
        exportData ? (
          <Button
            ghost
            icon={<DownloadOutlined />}
            type="primary"
            onClick={() => { }}
          />
        ) : null]}
      </div>
    </div>
  );
};

type PropsMultifirmaTable = {
  key?: string,
  rowClick?: (record: any) => void,
  onAdd?: () => void,
  exportData?: boolean,
  title?: string,
  data?: any[],
  cols?: any[],
  scroll?: any,
  expandable?: any,
  pagination?: any,
  expandedRowKeys?: any,
  loading?: boolean,
  handleSearch?: (selectedKeys, confirm, dataIndex, type) => void,
  handleReset?: (dataIndex, type) => void,
  handleSelectSearch?: (selectedKeys, confirm, dataIndex, table) => void,
  handleEdit?: (record) => void,
  handleView?: (record) => void,
  handleDelete?: (record) => void,
  actions?: (record) => any,
}

const MultifirmaTable = (props: PropsMultifirmaTable) => {
  const inputRef = useRef<any>(null);
  const searchFilter = ({ dataIndex, type }) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }: any) => (
      <div key="selesearch-dropdown-filter" style={{ padding: 8 }}>
        <Input
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => props.handleSearch(selectedKeys, confirm, dataIndex, type)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          ref={inputRef}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => props.handleSearch(selectedKeys, confirm, dataIndex, type)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              props.handleReset(dataIndex, type);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              props.handleSearch(selectedKeys, confirm, dataIndex, type);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => inputRef.current?.focus(), 100);
      }
    },
  });

  const dateRangeFilter = ({ dataIndex, type }) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }: any) => (
      <div key="date-dropdown-filter" style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
        <div className="range-picker-container">
          <RangePicker
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e)}
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => props.handleSearch(selectedKeys, confirm, dataIndex, type)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              props.handleReset(dataIndex, type);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              props.handleSearch(selectedKeys, confirm, dataIndex, type);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  });

  const selectFilter = ({ dataIndex, type, filterData }) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }: any) => (
      <div key="select-dropdown-filter" style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
        <div className="select-picker-container">
          {filterData.data.map(
            (f, i) => (
              <div className="select-checkbox">
                <Checkbox
                  onChange={(e) => {
                    if (!selectedKeys) {
                      setSelectedKeys(filterData.data.map((_) => null));
                    }
                    const auxSelected = [...selectedKeys];
                    if (e.target.checked) {
                      auxSelected[i] = e.target.value;
                    } else {
                      auxSelected[i] = null;
                    }
                    setSelectedKeys(auxSelected);
                  }}
                  value={f.value}
                  checked={selectedKeys[i]}
                >
                  {f.text}
                </Checkbox>
              </div>
            ),
          )}
        </div>
        <Space>
          <Button
            type="primary"
            onClick={
              () => props.handleSelectSearch(
                selectedKeys.filter((k) => k), confirm, filterData.dataIndex, filterData.table,
              )
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
              props.handleReset(filterData.dataIndex, type);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  });

  const getFilter = (col) => {
    switch (col.type) {
      case FilterTypes.Search: return searchFilter(col);
      case FilterTypes.DateRange: return dateRangeFilter(col);
      case FilterTypes.Select: return selectFilter(col);
      default: return {};
    }
  };

  const onRow = (record): any => ({
    onClick: () => {
      props.rowClick?.(record);
    },
  });

  const actionCols = () => {
    const {
      handleEdit, handleView, handleDelete, actions,
    } = props;
    if (handleEdit || handleView || handleDelete || actions) {
      return (
        <Column
          title=""
          render={(record) => (
            <div className="">
              {handleView ? (
                <Button
                  ghost
                  type="primary"
                  icon={<EyeOutlined />}
                  style={{ marginRight: 10 }}
                  onClick={() => handleView(record)}
                />
              ) : null}
              {handleEdit ? (
                <Button
                  ghost
                  icon={<EditOutlined />}
                  className="ant-btn-warning"
                  style={{ marginRight: 10 }}
                  onClick={() => handleEdit(record)}
                />
              ) : null}
              {handleDelete ? (
                <Popconfirm
                  placement="topRight"
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  title={`El usuario "${record.username}" se eliminara de forma permanente. \n¿Desea continuar?`}
                  onConfirm={() => handleDelete(record)}
                  onCancel={() => { }}
                  okText="Aceptar"
                  cancelText="Cancelar"
                >
                  <Button
                    ghost
                    danger
                    icon={<DeleteOutlined />}
                    className="ant-btn-warning"
                    style={{ marginRight: 10 }}
                  />
                </Popconfirm>
              ) : null}
            </div>
          )}
          key="col-actions"
          align="center"
          width="15%"
        />
      );
    }
    return null;
  };

  return (
    <div className="table-container">
      <TableHeader
        onAdd={props.onAdd}
        title={props.title}
        exportData={props.exportData}
      />
      <Table
        size="middle"
        bordered
        key={`${props.key || ''}`}
        loading={props.loading}
        expandable={props.expandable}
        className="multifirma-table"
        dataSource={props.data || []}
        rowKey={(record) => `row-${props.key || ''}-${record.id}`}
        onRow={onRow}
        pagination={props.pagination}
        scroll={props?.scroll}
      >
        {props.cols?.map((col) => (
          <Column
            title={col.title}
            dataIndex={col.dataIndex}
            render={col?.render}
            key={col.key}
            align={col?.align}
            width={col?.width}
            defaultSortOrder={col?.defaultSortOrder}
            sorter={col?.sorter}
            ellipsis={col?.ellipsis}
            {...getFilter(col)}
          />
        ))}
        {actionCols()}
      </Table>
    </div>
  );
};

export default MultifirmaTable;
export {
  FilterTypes,
};
