import {
  PieChartOutlined,
  MessageOutlined,
  UserOutlined,
  InteractionOutlined,
  PartitionOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import {
  Dashboard,
  UserList,
  SmsStats,
  IntegrationList,
  Integration,
  ProcessList,
  Process,
  UserActivityList,
} from '../pages';

const MainRoutes = {
  dash: {
    key: 'dashboard',
    name: 'Dashboard',
    route: '/',
    icon: PieChartOutlined,
    page: Dashboard,
    exact: true,
  },
  integrationList: {
    key: 'integrations',
    name: 'Integraciones',
    route: '/integration',
    icon: InteractionOutlined,
    page: IntegrationList,
  },
  smsList: {
    key: 'sms-stats',
    name: 'SMS',
    route: '/sms',
    icon: MessageOutlined,
    page: SmsStats,
  },
  processList: {
    key: 'processes',
    name: 'Procesos',
    route: '/sign/process',
    icon: PartitionOutlined,
    page: ProcessList,
  },
  userList: {
    key: 'users',
    name: 'Usuarios',
    route: '/user',
    icon: UserOutlined,
    exact: true,
    page: UserList,
  },
  activityList: {
    key: 'userActivity',
    name: 'Actividad',
    route: '/user/activity',
    icon: HistoryOutlined,
    page: UserActivityList,
  },
};

const ChildRoutes = {
  integration: {
    key: 'integration',
    name: 'Integracion',
    route: '/integration/:id',
    icon: UserOutlined,
    page: Integration,
  },
  process: {
    key: 'process',
    name: 'Process',
    route: '/process/:id',
    icon: PartitionOutlined,
    page: Process,
  },
};

export {
  MainRoutes, ChildRoutes,
};
