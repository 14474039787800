import moment from 'moment';

export const state: any = {
  processSummary: {
    loading: true,
  },
  selectedIntegration: 0,
  selectedYear: moment(),
  integrationList: [],
  availableYears: [],
  chartData: [],
};
