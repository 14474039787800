import React from 'react';
import { Breadcrumb } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { CaretRightOutlined } from '@ant-design/icons';
import { MainRoutes } from '../../config/routes';
import { useState, useActions } from '../../overmind';

const BreadCrumb = () => {
  const state = useState();
  const history:any = useHistory();
  const actions = useActions();
  const location = useLocation();

  const navigateMain = (bread) => {
    history.replace(bread.route);
    actions.app.navigateMain({
      ...bread,
      history: location.key,
    });
  };

  return (
    <Breadcrumb separator={<span className="bread-separator"><CaretRightOutlined /></span>}>
      <Breadcrumb.Item key="bread-multifirma" onClick={() => navigateMain(MainRoutes.dash)}>
        <span className="breadcrumb-index">
          Multifirma
        </span>
      </Breadcrumb.Item>
      {state.app.navigation.breadCrumb.map((bread, i) => {
        if (bread.route === '/') {
          return null;
        }
        if (i === 0) {
          return (
            <Breadcrumb.Item key={`bread-${bread.name}`} onClick={() => navigateMain(bread)}>
              <span className="breadcrumb-main">
                {bread.name}
              </span>
            </Breadcrumb.Item>
          );
        }
        return (<Breadcrumb.Item className="breadcrumb-text" key={`bread-${bread.name}`}>{bread.name}</Breadcrumb.Item>);
      })}
    </Breadcrumb>
  );
};

export default BreadCrumb;
