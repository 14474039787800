import React, { useEffect } from 'react';
import {
  Col, Descriptions, message, Row, Badge, Popover, Button,
} from 'antd';
import moment from 'moment';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { EditOutlined } from '@ant-design/icons';
import ReactJsonView from 'react-json-view';
import { ChildRoutes } from '../../config/routes';
import { useActions, useState } from '../../overmind';
import './Integration.css';
import IntegrationForm from '../../components/IntegrationForm/IntegrationForm';

const chartMonth = moment.monthsShort();

const Integration = () => {
  const { integration } = useState();
  const action = useActions();
  const history: any = useHistory();
  const { id }: any = useParams();
  const location = useLocation();

  useEffect(() => {
    action.integration.getIntegration(id)
      .then(() => {
        action.app.pushBread({
          ...ChildRoutes.integration,
          name: integration.integration.name,
          history: location.key,
        });
      });
    action.integration.getIntegrationProcess(id)
      .then((intProcessResponse) => {
        if (intProcessResponse.status === 'success') {
          // formatIntegrationProcessData(intProcessResponse.data);
        } else {
          message.error(`${intProcessResponse.data}`);
        }
      }).catch((error) => message.error(`${error}`));
  }, []);

  const formatIntegrationProcessData = (procesData) => {
    const convertedArray = chartMonth.map((key, index) => ({
      month: key,
      started: _.reduce(procesData, (sum, n) => {
        if (n.process_start_datetime && moment(n.process_start_datetime).month() === index) {
          return sum + 1;
        }
        return sum;
      }, 0),
      expired: _.reduce(procesData, (sum, n) => {
        if (n.expire_datetime && moment(n.expire_datetime).month() === index) {
          return sum + 1;
        }
        return sum;
      }, 0),
      canceled: _.reduce(procesData, (sum, n) => {
        if (n.cancel_datetime && moment(n.cancel_datetime).month() === index) {
          return sum + 1;
        }
        return sum;
      }, 0),
      finished: _.reduce(procesData, (sum, n) => {
        if (n.finish_datetime && moment(n.finish_datetime).month() === index) {
          return sum + 1;
        }
        return sum;
      }, 0),
    }));
    return convertedArray;
  };

  const ipList = (ips) => {
    const ipArr = ips?.replace(' ', '').split(',');
    const ipList = [];
    if (ipArr?.length > 0) {
      ipList.push(ipArr.map((ip: string) => <li>{ip}</li>));
    }
    return ipList;
  };

  const submitIntegration = (data) => {
    const {
      intName, finUrl, cancelUrl, intCustomData, intIp, intActive, id,
    } = data;
    const integrationData = {
      name: intName,
      url_sign_finish: finUrl,
      allowed_ips: intIp,
      url_sign_cancel: cancelUrl,
      integration_data_json: intCustomData,
      active: intActive ? 1 : 0,
    };
    action.integrationList.editIntegration({ ...integrationData, id })
      .then((response) => {
        if (response.status !== 'success') {
          message.error('No se h apodido editar la integracion');
        } else {
          action.integrationList.setFormVisible(false);
          message.success(`Integracion editada correctamente - ${response.data}`);
        }
      })
      .catch((error) => message.error(`Error: ${error}`));
  };

  const onEdit = (record) => {
    action.integrationList.setFormToEdit(record);
  };

  const canEdit = action.app.canAccess({ path: '/integration', method: 'PATCH' });

  return (
    <div className="integration-container">
      <IntegrationForm submitIntegration={submitIntegration} />
      <div className="integration-info">
        <Descriptions
          title="Informacion integracion"
          bordered
          layout="vertical"
          extra={
            canEdit
              ? (
                <Button
                  ghost
                  icon={<EditOutlined />}
                  className="ant-btn-warning"
                  style={{ marginRight: 10 }}
                  onClick={() => onEdit(integration.integration)}
                />
              )
              : null
          }
          column={4}
        >
          <Descriptions.Item label="Fecha creacion" span={2}>
            {integration.integration.creation_date}
          </Descriptions.Item>
          <Descriptions.Item label="Estado" span={2}>
            {integration.integration.active ? <Badge status="success" text="Activo" /> : <Badge status="error" text="No Activo" />}
          </Descriptions.Item>

          <Descriptions.Item label="URL fin proceso" span={2}>
            {integration.integration.url_sign_finish}
          </Descriptions.Item>
          <Descriptions.Item label="URL cancelar proceso" span={2}>
            {integration.integration.url_sign_cancel}
          </Descriptions.Item>
          <Descriptions.Item label="Datos adicionales respuesta" span={2}>
            <ReactJsonView
              style={
                {
                  overflowY: 'auto',
                }
              }
              src={
                integration.integration.integration_data_json?.length
                  ? JSON.parse(integration.integration.integration_data_json)
                  : {}
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Filtro IP" span={2} style={{ display: 'flex' }}>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {ipList(integration.integration.allowed_ips)}
            </ul>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};
export default Integration;
