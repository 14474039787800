/* eslint-disable no-param-reassign */
import { AsyncAction, Action } from 'overmind';
import { UserType, Common } from '../../interfaces';
import { IResponse } from '../../interfaces/common';

export const getUserActivityList: AsyncAction<void, IResponse> = async ({ effects, state }) => {
  state.userActivityList.loading = false;
  const response = await effects.userActivityList.getUserActivityListAPI(
    state.userActivityList.filters,
  );

  if (response.status === 200 && response.data.length > 0) {
    state.userActivityList = {
      ...state.userActivityList,
      loading: false,
      data: response.data,
    };
    return {
      status: 'success',
      data: response.data,
    };
  }
  state.userActivityList = {
    ...state.userActivityList,
    loading: false,
    error: response.data,
  };
  return {
    status: 'error',
    data: response.data,
  };
};

export const clearError: Action<void, void> = ({ state }) => {
  state.userActivityList.error = null;
};

export const resetFilter: Action<void, void> = ({ state }) => {
  state.userActivityList.filters = {};
};

export const addFilter: Action<{}, void> = (
  { state }, filter: any,
) => {
  state.userActivityList.filters[`${filter.dataIndex}`] = filter.value;
};
