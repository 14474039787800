/* eslint-disable no-param-reassign */
import { Action, AsyncAction } from 'overmind';
import { MainRoutes, ChildRoutes } from '../../config/routes';
import { Common } from '../../interfaces';
import { UserType } from '../../interfaces/User';
import AdminAxios from '../../utils/axios';
import { PathAndMethod } from '../../interfaces/common';

type BradRoute = {
};

const blockNavigation = (history) => {
  const unblock = history.block((tx: any) => {
    // Navigation was blocked! Let's show a confirmation dialog
    // so the user can decide if they actually want to navigate
    // away and discard changes they've made in the current page.
    const url = tx.location.pathname;
    if (window.confirm(`Are you sure you want to go to ${url}?`)) {
      // Unblock the navigation.
      unblock();
      // Retry the transition.
      tx.retry();
    }
  });
};

export const getPermissions: AsyncAction = async (
  { state, effects }, pathAndMethod,
) => {
  // devuelve tabla de permisos para el rol del usuario
  const accessibles = await effects.app.getPermissionsAPI(state.app.user.id);
  state.app.accessibles = accessibles.data;
};

// eslint-disable-next-line no-shadow
// eslint-disable-next-line max-len
export const navigateMain: AsyncAction<BradRoute> = async ({ state }, reoute: any) => {
  state.app.navigationMemory = [reoute.history];
  state.app.navigation.breadCrumb = [reoute];
};

export const pushBread: Action<BradRoute> = ({ state }, route: any) => {
  const { breadCrumb } = state.app.navigation;
  state.app.navigationMemory = [...state.app.navigationMemory, route.history];
  state.app.navigation.breadCrumb = [...breadCrumb, route];
  // history.push(route.route);
};

export const navigateBack: Action<BradRoute> = ({ state }, history: any) => {
  // state.app.navigation.breadCrumb = [route];
  const { breadCrumb } = state.app.navigation;
  const { navigationMemory } = state.app;
  if (navigationMemory[navigationMemory.length - 1] !== history.key) {
    breadCrumb.pop();
    state.app.navigation.breadCrumb = [...breadCrumb];
  }
};

export const logout: Action = ({ state }) => {
  state.app.user = {
    id: 0,
    username: '',
    password: '',
    role: '',
  };
  localStorage.removeItem('token');
};

export const setLoginModal: Action<boolean> = ({ state }, visible) => {
  state.app.showLogin = visible;
};

// eslint-disable-next-line no-shadow
// eslint-disable-next-line max-len
export const login: AsyncAction<UserType, Common.IResponse> = async ({ effects, state }, user: UserType) => {
  const response = await effects.app.loginAPI(user);

  if (response.data.status === 'success') {
    AdminAxios.defaults.headers.Authorization = `Bearer ${response.data.data.user.token}`;
    state.app.user = response.data.data.user;
    state.app.accessibles = response.data.data.permissions;
    localStorage.setItem('token', response.data.data.user.token);
    return {
      status: 'success',
      message: 'login_ok',
    };
  }
  return {
    status: 'error',
    message: 'login_no_ok',
  };
};

export const canAccess: Action<PathAndMethod, boolean> = (
  { state }, pathAndMethod: PathAndMethod,
) => state.app.accessibles?.find(
  (elem) => (
    elem.request_route === pathAndMethod.path
    && elem.request_method === pathAndMethod.method
  ),
) !== undefined;

export const authenticate: AsyncAction<void, Common.IResponse> = async ({ effects, state }) => {
  state.app.loading = true;

  try {
    if (!localStorage.getItem('token')) {
      throw new Error('auth_no_ok');
    }
    AdminAxios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    const response = await effects.app.authenticateAPI();
    if (response.data.status === 'success') {
      AdminAxios.defaults.headers.Authorization = `Bearer ${response.data.data.user.token}`;
      state.app.user = response.data.data.user;
      state.app.accessibles = response.data.data.permissions;
      localStorage.setItem('token', response.data.data.user.token);
      state.app.stored = true;
      state.app.loading = false;
      return {
        status: 'success',
        message: 'auth_ok',
      };
    }
    throw new Error('auth_no_ok');
  } catch (error) {
    AdminAxios.defaults.headers.Authorization = '';
    localStorage.removeItem('token');
    state.app.stored = false;
    state.app.loading = false;
    return {
      status: 'error',
      message: error,
    };
  }
};

export const setTheme: Action<string> = ({ state }, theme) => {
  state.app.theme = theme;
};
