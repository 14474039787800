import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const LineChart = ({ data }: any) => (
  <div style={{ width: '100%', height: 500, marginTop: 20 }}>
    <ResponsiveLine
      key="custom-line-chart"
      data={data}
      margin={{
        top: 10, right: 100, bottom: 50, left: 25,
      }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false,
      }}
      colors={{
        scheme: 'category10',
      }}
      curve="catmullRom"
      areaBaselineValue={0}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      animate
      pointSize={5}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  </div>
);

export default LineChart;
