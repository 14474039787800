import { IntegrationType } from '../../interfaces/Integration';

const data: IntegrationType[] = [];

export const state = {
  data,
  filters: {},
  formMode: 'create',
  formData: {},
  loading: true,
  formVisible: false,
};
