import React, { useEffect } from 'react';
import {
  Modal, Button, Form,
} from 'antd';

type PropsMultifirmaForm = {
  onCancel: () => void,
  onSubmit: (data: any) => void,
  children: React.ReactElement[],
  visible: boolean,
  title: string,
  data?: any,
  mode?: string,
}

const MultifirmaForm = ({
  onCancel = null, onSubmit, children, visible, title, mode = 'create', data,
}: PropsMultifirmaForm) => {
  const [form] = Form.useForm();
  const [changed, setChanged] = React.useState(false);

  useEffect(() => {
    if (mode === 'edit') {
      form.setFieldsValue(data);
    } else {
      form.resetFields();
    }
  }, [visible]);

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const checkIfChanged = (changedFields, allFields) => {
    if (mode === 'edit') {
      let valueChanged = false;
      allFields.forEach((field) => {
        if (field.value !== data[field.name[0]]) {
          valueChanged = true;
        }
      });
      setChanged(valueChanged);
    } else {
      let valueChanged = false;
      allFields.forEach((field) => {
        if (field.value && field.value !== '') {
          valueChanged = true;
        }
      });
      setChanged(valueChanged);
    }
  };

  const formFinish = (formData) => {
    if (mode === 'edit') {
      onSubmit({ ...formData, id: data.id });
    } else {
      onSubmit(formData);
    }
  };

  return (
    <Modal
      title={title}
      visible={visible}
      footer={[
        <Button
          onClick={() => handleCancel()}
        >
          Cancelar
        </Button>,
        <Button
          disabled={!changed}
          key={mode === 'create' ? 'crear-usuario' : 'editar-usuario'}
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          {mode === 'create' ? 'Crear' : 'Editar'}
        </Button>,
      ]}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        onFieldsChange={checkIfChanged}
        name={title.replace(/\s/, '-')}
        layout="vertical"
        onFinish={formFinish}
      >
        {children}
      </Form>
    </Modal>
  );
};

export default MultifirmaForm;
