/* eslint-disable no-param-reassign */
import { stat } from 'node:fs';
import { AsyncAction, Action } from 'overmind';
import { IResponse } from '../../interfaces/common';
import { ProcessType } from '../../interfaces/Process';

export const setProcess: Action<ProcessType> = ({ state }, process) => {
  state.process = { ...process };
};

export const getSingleProcess: AsyncAction<number, IResponse> = async (
  { effects, state }, processId: number,
) => {
  const response = await effects.processList.getProcessAPI(processId);
  if (response.status === 200) {
    state.process = { ...response.data };
    return {
      status: 'success',
      data: response.data,
    };
  }
  return {
    status: 'error',
    data: response.data,
  };
};

export const getProcessSigners: AsyncAction<number, IResponse> = async (
  { effects, state }, processId: number,
) => {
  state.process.loading = true;
  const response = await effects.process.getProcessSignersAPI(processId);
  if (response.status === 200) {
    state.process.loading = false;
    state.process.signers = response.data;
    return {
      status: 'success',
      data: response.data,
    };
  }
  return {
    status: 'error',
    data: response.data,
  };
};

export const getProcessRetries: AsyncAction<number, IResponse> = async (
  { effects, state }, processId: number,
) => {
  const response = await effects.process.getProcessRetriesAPI(processId);
  if (response.status === 200) {
    state.process.retries = response.data;
    return {
      status: 'success',
      data: response.data,
    };
  }
  return {
    status: 'error',
    data: response.data,
  };
};
