import { Moment } from 'moment';
import AdminAxios from '../../utils/axios';

const getprocessSummaryAPI = (
  filters: any[],
) => AdminAxios.get(
  `/sign/process/info?${filters.join('&')}`,
);

const getDashboardChartAPI = (filters) => {
  const query = `sign/process/chart?${filters.map((f) => (filters ? `${f}` : '')).join('&')}`;
  return AdminAxios.get(query);
};

const getIntegrationsAPI = () => AdminAxios.get('/integration');

export {
  getprocessSummaryAPI,
  getIntegrationsAPI,
  getDashboardChartAPI,
};
