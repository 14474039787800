/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState as useReactState } from 'react';
import {
  Avatar, Modal, Button, Form, Input, Checkbox, Dropdown, Menu, message,
} from 'antd';
import {
  UserOutlined,
  LockOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import DarkModeToggle from 'react-dark-mode-toggle';
import { useHistory } from 'react-router-dom';
import { useActions, useState } from '../../overmind';
import './Header.css';

const AdminAvatar = ({ onAvatarPressed, handleMenuClick }) => {
  const { app: { user } } = useState();

  if (user.id === 0) {
    return (
      <div className="avatar" onClick={onAvatarPressed} aria-hidden="true">
        <Button icon={<UserOutlined />} type="primary">Iniciar sesión</Button>
      </div>
    );
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<LogoutOutlined />}>
        Cerrar sesion
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className="user-avatar">
        <span className="avatar-username">{user.username}</span>
        <Avatar style={{ backgroundColor: '#2060cc' }} icon={<UserOutlined />} />
      </div>
    </Dropdown>
  );
};

const Header = () => {
  const actions = useActions();
  const { app: { user, theme }, sideColapsed } = useState();

  const avatarPressed = () => {
    actions.app.setLoginModal(true);
  };

  const handleMenuClick = (e) => {
    message.info('Click on menu item.');
    switch (e.key) {
      case '1':
        break;
      case '2': {
        break;
      }
      case '3': {
        actions.app.logout();
        break;
      }
      default:
        break;
    }
  };

  const toggleCollapsed = () => {
    actions.setSideNavCollapsed(!sideColapsed);
  };

  const setIsDarkMode = () => {
    actions.app.setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const isDarkMode = theme === 'dark';

  return (
    <>
      <div className="header">
        <div className="header-left">
          {user.id !== 0 ? (
            <Button type="primary" onClick={toggleCollapsed} ghost className="side-toggle">
              {React.createElement(sideColapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
            </Button>
          ) : null}
        </div>
        <div className="header-right">
          <div className="toggle-theme">
            <DarkModeToggle
              onChange={setIsDarkMode}
              checked={isDarkMode}
              size={60}
            />
          </div>
          <AdminAvatar
            onAvatarPressed={avatarPressed}
            handleMenuClick={handleMenuClick}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
