/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { Action, AsyncAction } from 'overmind';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { IntegrationType } from '../../interfaces/Integration';
import { IResponse } from '../../interfaces/common';

export const setIntegration: Action<IntegrationType> = ({ state }, integration) => {
  state.integration = { ...integration };
};

export const initialize: AsyncAction<void, void> = async ({ state, effects }) => {
  const { selectedIntegration, selectedYear, processSummary } = state.dashboard;
  try {
    const { dashboard, processList } = state;
    const filters = [];

    // add filters
    selectedIntegration && filters.push(`integration_id=${selectedIntegration}`);
    selectedYear && filters.push(`year=${selectedYear.year()}`);

    processSummary.loading = true;

    const auxState: any = {};

    await Promise.all([
      effects.dashboard.getDashboardChartAPI(filters).then((chartResponse) => {
        auxState.chartData = chartResponse.data.formattedData;
        auxState.years = chartResponse.data.years;
      }),
      effects.dashboard.getprocessSummaryAPI(filters).then((summaryResponse) => {
        auxState.processSummary = { ...summaryResponse.data };
      }),
    ]);

    const ay = auxState.years.map(
      (year) => parseInt(year.yearsAvailable, 10),
    );

    state.dashboard = {
      ...state.dashboard,
      chartData: auxState.chartData,
      processSummary: auxState.processSummary,
      availableYears: ay,
      loading: false,
    };
  } catch (error) {
    processSummary.loading = false;
  }
};

export const getprocessSummary: AsyncAction<void, IResponse> = async (
  { state, effects },
) => {
  try {
    const { selectedIntegration, selectedYear, processSummary } = state.dashboard;
    const filters = [];
    selectedIntegration && filters.push(`integration_id=${selectedIntegration}`);
    selectedYear && filters.push(`year=${selectedYear.year()}`);
    processSummary.loading = true;
    const response = await effects.dashboard.getprocessSummaryAPI(
      filters,
    );
    if (response.status === 200) {
      state.dashboard.processSummary = { ...response.data, loading: false };
      return {
        status: 'success',
        data: response.data,
      };
    }
    return {
      status: 'error',
      data: response.data,
    };
  } catch (error) {
    return {
      status: 'error',
      data: error,
    };
  }
};

export const getIntegrations: AsyncAction<void, IResponse> = async (
  { state, effects },
) => {
  try {
    const response = await effects.dashboard.getIntegrationsAPI();
    if (response.status === 200) {
      state.dashboard.integrationList = response.data;
      return {
        status: 'success',
        data: response.data,
      };
    }
    return {
      status: 'error',
      data: response.data,
    };
  } catch (error) {
    return {
      status: 'error',
      data: error,
    };
  }
};

export const setSelectedIntegration: Action<number> = ({ state }, integration_id: number) => {
  state.dashboard.selectedIntegration = integration_id;
};

export const setSelectedYear: Action<Moment> = ({ state }, year: Moment) => {
  state.dashboard.selectedYear = year;
};

export const setAvailableYears: Action<any> = ({ state }) => {
  let allYears = [];
  state.processList.data.forEach((process) => {
    const uniqueYears = new Set([
      moment(process.process_start_datetime).year(),
      process.cancel_datetime && moment(process.cancel_datetime).year(),
      process.expire_datetime && moment(process.expire_datetime).year(),
      process.finish_datetime && moment(process.finish_datetime).year(),
    ]);

    allYears = [...allYears, ...Array.from(uniqueYears)];
  });

  const uniqueAllYears = _.compact(Array.from(new Set(allYears)).sort((a, b) => a - b));
  state.dashboard.availableYears = uniqueAllYears;
};
