/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';

type BarChartType = {
  data: any[],
  keys: string[],
  indexBy: string,
  axisBottom: string,
  axisLeft: string,
};

const BarChart = ({
  data, keys, indexBy, axisBottom, axisLeft,
}: BarChartType) => {
  const [chartKeys, setChartKeys] = useState([]);

  const hideValue = () => {
    const aux = {};
    chartKeys.forEach((k) => {
      aux[k] = 0;
    });
    const auxData = data.map((col) => ({
      ...col,
      ...aux,
    }));
    return auxData;
  };

  return (
    <ResponsiveBar
      data={hideValue()}
      keys={keys}
      indexBy={indexBy}
      onClick={(record, event) => {
      }}
      margin={{
        top: 50, right: 100, bottom: 50, left: 60,
      }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{
        scheme: 'category10',
      }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: axisBottom,
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: axisLeft,
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          onClick: (record, event: any) => {
            const index = chartKeys.indexOf(record.id.toString());
            if (index === -1) {
              event.target.parentNode.style.opacity = '0.3';
              setChartKeys([...chartKeys, record.id.toString()]);
            } else {
              event.target.parentNode.style.opacity = '0.85';
              setChartKeys(chartKeys.filter((f) => f !== record.id.toString()));
            }
          },
        },
      ]}
      animate
      motionStiffness={90}
      motionDamping={15}
      isInteractive
    />
  );
};

export default BarChart;
