import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { MainRoutes } from '../../config/routes';
import { useState, useActions } from '../../overmind';

const SideNavigation = () => {
  const { sideColapsed, app: { navigation, theme } } = useState();
  const { app } = useActions();
  const history: any = useHistory();
  const location = useLocation();

  const handleNavigate = async (route) => {
    history.replace(route.route);
    app.navigateMain({
      ...route,
      history: location.key,
    });
  };

  const navClicked = (item) => {
  };

  return (
    <Menu
      style={{ maxWidth: 256 }}
      className="side-navigation"
      defaultSelectedKeys={[MainRoutes.dash.key]}
      defaultOpenKeys={['sub1']}
      mode="inline"
      inlineCollapsed={sideColapsed}
      theme={theme}
      selectedKeys={navigation.breadCrumb[0]?.key || ''}
      onClick={navClicked}

    >
      {Object.keys(MainRoutes).map((key) => {
        const route = MainRoutes[key];

        return app.canAccess({ path: route.route, method: 'GET' })
          ? (
            <Menu.Item
              key={route.key}
              icon={<route.icon />}
              onClick={() => handleNavigate(route)}
            >
              {route.name}
            </Menu.Item>
          ) : '';
      })}
    </Menu>
  );
};

export default SideNavigation;
