import React, { useEffect } from 'react';
import {
  Avatar, Modal, Button, Form, Input, Checkbox, Dropdown, Menu, message,
} from 'antd';
import {
  UserOutlined,
  LockOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { UserType } from '../../interfaces/User';
import { MainRoutes } from '../../config/routes';
import { useActions, useState } from '../../overmind';
import Logo from '../../assets/img/good_team.png';
import './Splash.css';

const LoginModal = ({ visible, handleOk, handleCancel }: any) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Login"
      onCancel={handleCancel}
      footer={[
        <Button
          key="Iniciar sesion"
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          Iniciar sesion
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={handleOk}
        initialValues={{ remember: true }}
      >
        <Form.Item
          name="login_username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="login_password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            iconRender={(passVisible) => (passVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Recordar usuario</Checkbox>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Splash = () => {
  const state = useState();
  const actions = useActions();
  const history: any = useHistory();
  const location = useLocation();

  useEffect(() => {

  }, []);

  const modalOk = async (data) => {
    const { login_username, login_password } = data;
    const logedUser: UserType = {
      username: login_username,
      password: CryptoJS.SHA1(login_password).toString(),
    };
    const response = await actions.app.login(logedUser);

    if (response.status !== 'success') {
      message.error('Usuario y/o contraseña incorrecta');
    } else {
      actions.app.setLoginModal(false);
      // actions.app.navigateMain({ route: MainRoutes[0], history: location.key });
      // history.replace(MainRoutes.dash.route);
    }
  };

  const modalCancel = () => {
    actions.app.setLoginModal(false);
  };

  return (
    <div className="splash-view">
      <img src={Logo} className="logo" alt="logo" />
      <div className="app-title">Multifirma admin panel v3</div>
      <LoginModal
        visible={state.app.showLogin}
        handleOk={modalOk}
        handleCancel={modalCancel}
      />
    </div>
  );
};

export default Splash;
