/* eslint-disable no-param-reassign */
import { AsyncAction, Action } from 'overmind';
import { IResponse } from '../../interfaces/common';

type intData = {

};

export const getIntegrationList: AsyncAction<void, IResponse> = async (
  { effects, state },
) => {
  state.integrationList.loading = true;
  const response = await effects.integrationList.getIntegrationListAPI(
    state.integrationList.filters,
  );
  if (response.status === 200) {
    state.integrationList.loading = false;
    state.integrationList.data = [...response.data];
    return {
      status: 'success',
      data: response.data,
    };
  }
  return {
    status: 'error',
    data: response.data,
  };
};

export const resetFilter: Action<void, void> = ({ state }) => {
  state.integrationList.filters = {};
};

export const addFilter: Action<intData, void> = (
  { state }, filter: any,
) => {
  state.integrationList.filters[`${filter.dataIndex}`] = filter.value;
};

export const editIntegration: AsyncAction<intData, IResponse> = async (
  { effects }, integrationData: any,
) => {
  const { id } = integrationData;
  delete integrationData.id;
  const response = await effects.integrationList.patchIntegrationAPI(id, integrationData);
  if (response.status === 200) {
    return {
      status: 'success',
      data: response.data,
    };
  }
  return {
    status: 'error',
    data: response.data,
  };
};

export const createIntegration: AsyncAction<intData, IResponse> = async (
  { effects }, integrationData: any,
) => {
  const response = await effects.integrationList.postIntegrationAPI(integrationData);
  if (response.status === 200) {
    return {
      status: 'success',
      data: response.data,
    };
  }
  return {
    status: 'error',
    data: response.data,
  };
};

export const setFormToEdit: Action<intData, void> = ({ state }, record: any) => {
  state.integrationList.formData = {
    id: record.id,
    intName: record.name,
    finUrl: record.url_sign_finish,
    cancelUrl: record.url_sign_cancel,
    intCustomData: record.integration_data_json,
    intIp: record.allowed_ips,
    intActive: Boolean(record.active),
  };
  state.integrationList.formMode = 'edit';
  state.integrationList.formVisible = true;
};

export const setFormVisible: Action<boolean, void> = ({ state }, visible) => {
  state.integrationList.formMode = 'create';
  state.integrationList.formVisible = visible;
};
