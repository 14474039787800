import React, { useEffect, useState as reactUseState } from 'react';
import {
  useHistory, Switch, Route, useRouteMatch, useLocation,
} from 'react-router-dom';
import { message, Tag } from 'antd';
import { useActions, useState } from '../../overmind';
import { ChildRoutes, MainRoutes } from '../../config/routes';
import Process from './Process';
import MultifirmaTable, { FilterTypes } from '../../components/MultifirmaTable/MultifirmaTable';

const cols = [
  {
    title: 'Integración',
    dataIndex: 'integrationName',
    key: 'name',
    type: FilterTypes.Search,
    width: '30%',
    sorter: (a, b) => a.integrationName.localeCompare(b.integrationName),
  },
  {
    title: 'Fecha inicio',
    dataIndex: 'process_start_datetime',
    key: 'process_start',
    defaultSortOrder: 'descend',
    type: FilterTypes.DateRange,
    width: '20%',
    sorter: (a, b) => new Date(
      a.process_start_datetime,
    ).getTime() - new Date(
      b.process_start_datetime,
    ).getTime(),
  },
  {
    title: 'Estado',
    dataIndex: 'processState',
    key: 'process-state',
    align: 'center',
    render: (rolename) => {
      const rn = rolename;
      let color;
      switch (rn) {
        case 'active': color = 'geekblue';
          break;
        case 'cancelled': color = 'red';
          break;
        case 'expired': color = 'darkgray';
          break;
        case 'finished': color = 'green';
          break;
        default: return (<div> undefined </div>);
      }
      return (
        <Tag color={color} key={rn}>
          {rn.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: 'Duración',
    dataIndex: 'process_max_duration_days',
    key: 'process_duration',
    align: 'center',
    render: (duration) => (`${duration} días`),
  },
];

const ProcessList = () => {
  const { processList, integrationList } = useState();
  const { path } = useRouteMatch();
  const history: any = useHistory();
  const actions = useActions();
  const location = useLocation();
  useEffect(() => {
    actions.app.navigateMain({
      ...MainRoutes.processList,
      history: location.key,
    });
    getProcessTableData();
  }, []);

  const getProcessTableData = async () => {
    const result = await actions.processList.getProcessList();
    if (result.status === 'error') {
      message.error(`${result.data}`);
    }
  };

  const rowClicked = (record) => {
    history.push(`/sign/process/${record.id}`);
  };

  const onSearch = (selectedKeys, confirm, dataIndex, type): void => {
    if (type === FilterTypes.DateRange) {
      actions.processList.addFilter({
        dataIndex: 'from',
        value: selectedKeys[0]?.format('YYYY-MM-DD'),
      });
      actions.processList.addFilter({
        dataIndex: 'to',
        value: selectedKeys[1]?.format('YYYY-MM-DD'),
      });
    } else {
      actions.processList.addFilter({
        dataIndex,
        value: selectedKeys[0],
      });
    }
    getProcessTableData();
    confirm();
  };

  const onReset = (dataIndex, type) => {
    if (type === FilterTypes.DateRange) {
      actions.processList.addFilter({
        dataIndex: 'from',
        value: null,
      });
      actions.processList.addFilter({
        dataIndex: 'to',
        value: null,
      });
    } else {
      actions.processList.addFilter({
        dataIndex,
        value: null,
      });
    }
    getProcessTableData();
  };

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <Process />
      </Route>
      <Route exact path={path}>
        <div className="process-list-page">
          <MultifirmaTable
            loading={processList.loading}
            title="Lista de procesos"
            cols={cols}
            data={processList.data}
            handleSearch={onSearch}
            handleReset={onReset}
            handleView={rowClicked}
          />
        </div>
      </Route>
    </Switch>
  );
};

export default ProcessList;
