import AdminAxios from '../../utils/axios';

const getProcessListAPI = (filters) => AdminAxios.get(`/sign/process?${Object.keys(filters).map((f) => (filters[f] ? `${f}=${filters[f]}` : '')).join('&')}`);
const postProcessAPI = (processData) => AdminAxios.post('/sign/process', processData);
const getProcessAPI = (id) => AdminAxios.get(`/sign/process/${id}`);
const getProcessByIntegrationAPI = (integrationId) => AdminAxios.get(`/sign/process/?integration_id=${integrationId}`);

export {
  getProcessListAPI,
  postProcessAPI,
  getProcessAPI,
  getProcessByIntegrationAPI,
};
