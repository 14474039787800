import { ProcessType } from '../../interfaces/Process';

export const state: ProcessType = {
  id: 0,
  integration_id: 0,
  name: '',
  client_document_id: 0,
  process_start_datetime: null,
  last_sign_datetime: null,
  expire_datetime: null,
  cancel_datetime: null,
  finish_datetime: null,
  process_max_duration_days: 0,
  num_errors: 0,
  doc_identity: '',
  signers: [],
  retries: [],
  SentSms: 0,
  loading: true,
};
