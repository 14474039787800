import moment from 'moment';

const data: any[] = [];

export const state = {
  data,
  filters: {},
  loading: true,
  chartData: [],
  availableYears: [],
  integrationList: [],
  selectedIntegration: 0,
  selectedYear: moment(),
};
