import axios from 'axios';

const adminAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
  headers: {
    token: `${process.env.REACT_APP_API_TOKEN}`,
  },
});

adminAxios.defaults.headers.common.utoken = 'pepe';

export default adminAxios;
