import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useState, useActions } from './overmind';
import Header from './components/Header/Header';
import SideNavigation from './components/SideNavigation/SideNavigation';
import BreadCrumb from './components/BreadCrumb/BreadCrumb';
import { MainRoutes, ChildRoutes } from './config/routes';

import {
  Splash,
} from './pages';

// const { SubMenu } = Menu;

const AdminScreens = () => {
  const { app: { user, navigation, navigationMemory } } = useState();
  const { app } = useActions();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    history.listen((location) => {
      if (history.action === 'POP') {
        app.navigateBack(location);
      }
    });
  }, []);

  if (user.id === 0) {
    return (
      <>
        {/* <Redirect to="/" /> */}
        <Switch>
          <Route path="/">
            <Splash />
          </Route>
        </Switch>
      </>
    );
  }

  return (
    <div className="content-sidebar-view">
      <SideNavigation />
      <div className="main-content">
        <div className="container-mid">
          <div className="header-center">
            <BreadCrumb />
          </div>
          <div className="page-content">
            <Switch>
              {Object.keys(MainRoutes).map((key) => {
                const route = MainRoutes[key];
                return (
                  <Route path={route.route} key={route.key} exact={route.exact || false}>
                    <route.page />
                  </Route>
                );
              })}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminRouter = () => (
  <Router>
    <div className="main">
      <Header />
      <AdminScreens />
    </div>
  </Router>
);

export default AdminRouter;
